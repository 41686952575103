import { AnimateIn } from '@/components/common'
import Image from '@/components/contentful/Image'
import { contentUtil, richText } from '@/utils'
import { Document } from '@contentful/rich-text-types'
import { Asset } from 'contentful'
import { ISectionContentBlock } from 'src/types/generated/contentful'

interface Props {
  items?: ISectionContentBlock[]
}

interface Testimonial {
  id: string
  contentType: 'contentBlock'
  image?: Asset
  subtitle?: string
  text?: Document
  title: string
}

const Testimonials = ({ items = [] }: Props) => {
  const testimonials = items.map(
    contentUtil.mapContent
  ) as unknown as Testimonial[]

  return (
    <div>
      {testimonials.map(({ id, image, subtitle, text, title }) => (
        <AnimateIn variant="slideOver" className="group" key={id}>
          <div className="items-center m-3 md:my-6 hidden md:flex">
            {image && (
              <Image
                image={image}
                className="aspect-square object-cover rounded-md"
                containerClassName="flex-[2] group-odd:pr-8 group-even:pl-8 group-even:order-2"
                width={262}
                height={262}
                fit="fill"
                focus="face"
                lazy
              />
            )}
            <div style={{ flex: 6 }}>
              {text && (
                <div className="italic mb-2 text-lg">{richText(text)}</div>
              )}
              <div className="uppercase w-full tracking-widest text-gray text-md">
                {title}
              </div>
              {subtitle && (
                <div className="uppercase w-full tracking-widest text-gray text-xs">
                  {subtitle}
                </div>
              )}
            </div>
          </div>
          <div className="items-center m-3 my-5 flex flex-col md:hidden text-center">
            {image && (
              <Image
                image={image}
                width={160}
                height={160}
                fit="fill"
                focus="face"
                className="object-cover"
                containerClassName="rounded-full w-[160px] h-[160px] mb-4 overflow-hidden shadow-[0px_7px_10px_#C0C0C0]"
                lazy
              />
            )}
            <div style={{ flex: 6 }}>
              {text && (
                <div className="italic mb-2 text-lg">{richText(text)}</div>
              )}
              <div className="uppercase w-full tracking-widest text-gray text-sm">
                {title}
              </div>
              {subtitle && (
                <div className="uppercase w-full tracking-widest text-gray text-sm">
                  {subtitle}
                </div>
              )}
            </div>
          </div>
        </AnimateIn>
      ))}
    </div>
  )
}

export default Testimonials
