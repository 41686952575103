import React from 'react'
import ContentfulExperiment from '@/components/contentful/ContentfulExperiment'
import { Experiment } from '@/components/contentful/ContentfulExperiment/types'
import { Props as VideoHeroProps } from './VideoHero'
import MobileFallbackVideoHero from './MobileFallbackVideoHero'
import { IVideoBlock } from 'src/types/generated/contentful'

export interface Props extends Omit<VideoHeroProps, 'video'> {
  headerVideo: Experiment<IVideoBlock>
}

const ABVideoHero: React.FC<Props> = ({ headerVideo, ...rest }) => {
  const resolvedAspectRatio =
    headerVideo.variations[0]?.fields.aspectRatio || 16 / 9

  return (
    <ContentfulExperiment
      variations={headerVideo.variations}
      experimentMeta={headerVideo.experimentMeta}
      fallback={
        <div
          className="w-full bg-gray-8"
          style={{
            paddingBottom: `${100 / resolvedAspectRatio}%`,
          }}
        />
      }
    >
      {(variation) => {
        return (
          <MobileFallbackVideoHero
            {...rest}
            video={variation.fields.id}
            service={variation.fields.service}
            aspectRatio={variation.fields.aspectRatio}
          />
        )
      }}
    </ContentfulExperiment>
  )
}

export default ABVideoHero
