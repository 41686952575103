import React, { useState, createContext, useMemo, useContext } from 'react'
import Dynamic from '../../contentful/Dynamic'
import { contentUtil } from '@/utils'
import { CONTENT_BLOCK_LAYOUT } from '../../../../constants/contentful'
import { Campaign } from '@/services/api/campaign'
import { Content } from '@/services/contentful/types'

interface Tab {
  title?: string
  linkText?: string
  id?: string
  visible?: boolean
  Component?: () => JSX.Element
}

interface Context {
  tabs: Tab[]
  selectedTab: number
  setTab: (tab: number) => void
}

const DetailsContext = createContext<Context>({
  tabs: [],
  selectedTab: 0,
  setTab: () => null,
})

const TAB_IDS = {
  faqSection: 'section-faq',
  mediaGallery: 'media-gallery',
  sectionCastAndCrew: 'section-cast-and-crew',
  [`sectionContentBlock${CONTENT_BLOCK_LAYOUT.CARD}`]: 'section-how-it-works',
  [`sectionContentBlock${CONTENT_BLOCK_LAYOUT.PERKS}`]: 'section-perks',
  sectionLivestream: 'section-livestream',
  sectionOffering: 'section-offering',
  sectionOverview: 'section-overview',
  sectionPerks: 'section-perks',
}

export const useDetailsContext = () => useContext(DetailsContext)

export interface DetailsProviderProps {
  campaign: Campaign
  content: Content[]
  enableLegacyLivestreams: boolean
}

export const DetailsProvider: React.FC<DetailsProviderProps> = ({
  children,
  campaign,
  content = [],
  enableLegacyLivestreams,
}) => {
  const [tab, setTab] = useState(-1)

  const tabs = useMemo(() => {
    const allTabs = content
      .map((c, idx) => {
        const componentContent = contentUtil.mapContent(c)
        if (!componentContent) return {}

        let isVisible = true
        if (componentContent.contentType === 'faqSection') {
          isVisible = !!componentContent.faqs?.length
        } else if (componentContent.contentType === 'mediaGallery') {
          isVisible = !!componentContent.items?.length
        } else if (componentContent.contentType === 'sectionLivestream') {
          componentContent.enableLegacyLivestreams = enableLegacyLivestreams
          isVisible =
            !enableLegacyLivestreams ||
            (enableLegacyLivestreams && !!componentContent.livestreams?.length)
        } else if (
          componentContent.layout === 'perks' ||
          componentContent.contentType === 'sectionPerks'
        ) {
          isVisible = !!campaign.perks.length
        }

        const id =
          TAB_IDS[
            componentContent.contentType + (componentContent.layout || '')
          ] + componentContent.id

        return {
          title: componentContent.title,
          linkText: componentContent.linkText,
          id,
          visible: isVisible,
          Component() {
            return (
              <Dynamic
                key={componentContent.id}
                content={componentContent}
                campaign={campaign}
                idx={idx}
              />
            )
          },
        }
      })
      .filter((t) => !!t.id)

    if (campaign.status === 'testing_the_waters') {
      return allTabs.filter(
        ({ id }) =>
          !id?.includes('section-offering') && !id?.includes('section-perks')
      )
    }

    return allTabs
  }, [campaign, content, enableLegacyLivestreams])

  return (
    <DetailsContext.Provider
      value={{
        tabs: tabs.filter(({ visible }) => visible),
        selectedTab: tab,
        setTab,
      }}
    >
      {children}
    </DetailsContext.Provider>
  )
}
