import React from 'react'
import { Type } from '@/components/common'
import classNames from 'classnames'
import { ArrowRight } from '@/components/svg'

export interface Props {
  idx: number
  id?: string
  title?: string
  Component?: () => JSX.Element
  itemsRef: { current: (HTMLElement | null)[] }
}

const ComponentWrap: React.FC<Props> = ({
  idx,
  id,
  itemsRef,
  title,
  Component,
}) => {
  if (!Component) return null

  return (
    <div
      key={id}
      id={id}
      data-title={title}
      ref={(el) => (itemsRef.current[idx] = el)}
      className={classNames(
        'bg-core-white flex flex-col justify-center mb-6 px-6 py-8 relative lg:mx-24 lg:rounded-2xl 2lg:mx-0',
        // only apply style if it's the first section, and it's an overview section
        {
          'first:bg-transparent first:mb-16 first:py-0 lg:first:px-0':
            id?.includes('section-overview'),
        }
      )}
    >
      {idx > 0 && (
        <>
          <ArrowRight className="absolute left-0 top-8 text-core-oxide-core" />
          <Type as="h2" variant="title-lg" className="font-bold mb-6">
            {title}
          </Type>
        </>
      )}
      <Component />
    </div>
  )
}

export default ComponentWrap
