import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import InvestButton, { Props as InvestButtonProps } from './InvestButton'
import useOnScreen from '@/services/hooks/useOnScreen'
import ABTester, { ABTestProps } from '@/components/ABTester'
import MinInvestment from './MinInvestment'
import useHydration from '@/services/hooks/useHydration'

type Props = InvestButtonProps & {
  showMinInvestment?: boolean
  topInvestBtnRef: React.RefObject<Element>
}

export const MobileInvestButtonWrapper: React.FC<Props> = ({
  campaign,
  showMinInvestment,
  topInvestBtnRef,
  ...investButtonProps
}) => {
  const ref = useRef(null)
  const isVisible = useOnScreen(ref)
  const topCtaIsVisible = useOnScreen(topInvestBtnRef)

  useEffect(() => {
    if (!document) return

    document.documentElement.style.setProperty(
      '--zendesk-bottom-position',
      !isVisible || topCtaIsVisible ? '0.75rem' : '5.5rem'
    )
  }, [isVisible, topCtaIsVisible])

  return (
    <div
      className={classNames(
        'bg-white lg:hidden sticky bottom-0 left-0 px-6 py-4 right-0 text-center z-50',
        {
          hidden: topCtaIsVisible,
        }
      )}
      style={{ boxShadow: '0px -8px 8px rgba(106, 115, 129, 0.12)' }}
      ref={ref}
    >
      <InvestButton
        {...investButtonProps}
        campaign={campaign}
        className="max-w-[420px] text-sm w-full"
        rounded
        size={'md'}
      />

      {showMinInvestment && (
        <MinInvestment
          campaign={campaign}
          preset="custom"
          className="uppercase text-xs font-semibold mt-2 text-gray tracking-wide"
        />
      )}
    </div>
  )
}

const ABMobileInvestButtonWrapper: React.FC<Props> = ({
  campaign,
  ...props
}) => {
  const { isHydrated } = useHydration()

  if (!isHydrated) return null

  if (campaign.status === 'currently_funding') {
    return (
      <ABTester name="min_investment">
        {({ decision }: ABTestProps) => {
          return (
            <MobileInvestButtonWrapper
              campaign={campaign}
              {...props}
              showMinInvestment={decision.variationKey === 'on'}
            />
          )
        }}
      </ABTester>
    )
  }
  return <MobileInvestButtonWrapper {...props} campaign={campaign} />
}

export default ABMobileInvestButtonWrapper
