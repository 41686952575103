import React from 'react'
import VariationChooser from './VariationChooser'
import { Experiment, Variation } from './types'
import useHydration from '@/services/hooks/useHydration'

function ContentfulExperiment<T extends Variation>({
  variations,
  experimentMeta,
  fallback = null,
  children,
}: Experiment<T>) {
  // Avoid static-rendering/hydration issues by not rendering until after app is hydrated
  const { isHydrated } = useHydration({ waitForAnimationFrame: true })

  if (!isHydrated) {
    return fallback
  }

  // Handle no variations
  if (variations.length === 0) return null

  // Handle only one variation, or incomplete experiment setup
  if (variations.length === 1 || !experimentMeta) {
    return children(variations[0])
  }

  // Handle variation winner already declared
  const winningEntry = variations.find(
    ({ sys }) => sys.id === experimentMeta?.winningEntryId
  )
  if (winningEntry) {
    return children(winningEntry)
  }

  // Else, run the experiment
  return (
    <VariationChooser variations={variations} experimentMeta={experimentMeta}>
      {children}
    </VariationChooser>
  )
}

export default ContentfulExperiment
