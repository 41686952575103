import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { Campaign } from '@/services/api/campaign'
import { IPageCampaign, IVideoBlock } from 'src/types/generated/contentful'
import { AnimateIn, CampaignShareBox, VideoModal } from '@/components/common'
import Details from '@/components/pages/CampaignPages/Details'
import DetailsTabs from '@/components/pages/CampaignPages/DetailsTabs'
import { DetailsProvider } from '@/components/pages/CampaignPages/DetailsContext'
import InvestCard from '@/components/pages/CampaignPages/InvestCard'
import PublicComments from '@/components/pages/CampaignPages/PublicComments'
import VideoHero from '@/components/pages/CampaignPages/VideoHero'
import MobileInvestButtonWrapper from '@/components/pages/CampaignPages/InvestComponents/MobileInvestButtonWrapper'
import PriorRaisesInvestCard from '@/components/pages/CampaignPages/InvestComponents/PriorRaisesInvestCard'
import { contentUtil } from '@/utils'
import fbPixel, { getCampaignPixels } from '@/services/analytics/fbPixel'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { SiteAssets } from '@/services/api/siteAssets'
import { VideoService } from '@/components/common/VideoBox'
import { Experiment } from '@/components/contentful/ContentfulExperiment'
import Markdown from '@/components/Markdown'
import useLivestreams from '@/services/hooks/useLivestreams'

export interface Props {
  campaign: Campaign
  content?: IPageCampaign
  headerVideo: { fields: Experiment<IVideoBlock> }
  siteAssets: SiteAssets
}

const CampaignPage: React.FC<Props> = ({
  campaign,
  content,
  headerVideo,
  siteAssets,
}) => {
  const router = useRouter()
  const topInvestBtnRef = useRef(null)
  const { start_video: startVideo, start_video_service: startVideoService } =
    router.query
  const [modalVideo, setModalVideo] = useState<{
    id: string
    service?: VideoService
    aspectRatio?: number
  } | null>(null)
  const pageContent = content && contentUtil.mapContent(content)

  const { livestreams } = useLivestreams(campaign.slug)

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.dataLayer.push({
        event: 'landing-page',
        campaign: campaign.name,
        status: campaign.regulationType,
      })
    }
  }, [campaign])

  useEffect(() => {
    const serviceIsValid =
      typeof startVideoService === 'undefined' ||
      startVideoService === 'youtube' ||
      startVideoService === 'vimeo'
    if (typeof startVideo === 'string' && serviceIsValid) {
      setModalVideo({
        id: startVideo,
        service: startVideoService as VideoService,
      })
    }
  }, [startVideo, startVideoService])

  useEffect(() => {
    if (campaign) {
      fbPixel.init(getCampaignPixels(campaign))
      fbPixel.pageView()
      SegmentHandler.track(
        'Campaign Pageview',
        { campaign: campaign.name, project: campaign.slug },
        { suppressSegmentTrack: true }
      )
    }
  }, [campaign])

  return (
    <div className="min-h-screen">
      <DetailsProvider
        campaign={campaign}
        content={pageContent?.content}
        enableLegacyLivestreams={!livestreams?.length}
      >
        <VideoHero
          headerVideo={headerVideo.fields}
          campaign={campaign}
          setModalVideo={setModalVideo}
          modalIsOpen={!!modalVideo}
          anticipatedRegulation={pageContent?.anticipatedRegulation?.fields}
        />

        <InvestCard
          investButtonWrapperRef={topInvestBtnRef}
          campaign={campaign}
          className={[
            'max-w-[640px] mx-auto pb-5 pt-8 px-4',
            'md:rounded md:pb-8 md:pt-10',
            '2lg:hidden',
          ].join(' ')}
          sectionName="body"
          anticipatedRegulation={pageContent?.anticipatedRegulation?.fields}
        />

        {siteAssets.usePriorRaisesLayout && (
          <div className="mt-6 px-4 max-w-[960px] md:px-6 lg:mx-auto 2lg:hidden">
            <Markdown source={siteAssets.campaignSummary} />

            <PriorRaisesInvestCard
              investButtonWrapperRef={topInvestBtnRef}
              campaign={campaign}
              className="border border-core-gray-200 rounded p-5 md:p-6 mt-6"
            />
          </div>
        )}

        <DetailsTabs campaign={campaign} />

        <div className="h-full flex flex-col-reverse max-w-[1264px] my-6 lg:my-14 lg:mx-auto 2lg:grid 2lg:grid-cols-3 2lg:gap-8 2lg:mx-8 min-[1328px]:mx-auto">
          <div className="flex flex-col 2lg:col-span-2">
            <Details />

            {campaign?.regulationType === 'CF' && (
              <PublicComments
                isOpen={campaign.status === 'currently_funding'}
                campaignName={campaign.name}
                campaignSlug={campaign.slug}
                className="bg-core-white flex flex-col justify-center mb-6 px-6 py-8 relative lg:mx-24 lg:rounded-2xl 2lg:mx-0"
              />
            )}

            {siteAssets.cognitoFormsId && siteAssets.cognitoFormsKey && (
              <AnimateIn
                variant={{
                  easing: 'ease-out',
                  from: 'opacity-0 translate-y-[25%]',
                  to: 'opacity-100 translate-y-0',
                }}
                className="mb-6 md:mb-8"
              >
                <iframe
                  id="whitelabel-contact-form"
                  src={`https://www.cognitoforms.com/f/${siteAssets.cognitoFormsKey}/${siteAssets.cognitoFormsId}`}
                  className="bg-white border-0 w-full lg:rounded-2xl p-6 pb-0"
                  height="550"
                ></iframe>
              </AnimateIn>
            )}

            <CampaignShareBox
              campaign={campaign}
              className="lg:hidden w-full mb-6"
            />
          </div>

          <div className="hidden 2lg:block">
            <div className="sticky top-24">
              {siteAssets.usePriorRaisesLayout ? (
                <PriorRaisesInvestCard
                  campaign={campaign}
                  className="bg-white p-6 border border-core-gray-200 rounded-2xl"
                />
              ) : (
                <InvestCard
                  campaign={campaign}
                  className="bg-white p-6 border border-core-gray-200 rounded-2xl"
                  sectionName="sidebar"
                  anticipatedRegulation={
                    pageContent?.anticipatedRegulation?.fields
                  }
                />
              )}
            </div>
          </div>
        </div>

        <MobileInvestButtonWrapper
          campaign={campaign}
          color="oxide"
          sectionName="body"
          topInvestBtnRef={topInvestBtnRef}
        />
      </DetailsProvider>

      <VideoModal
        isOpen={!!modalVideo?.id}
        setIsOpen={(isOpen) => setModalVideo(isOpen ? modalVideo : null)}
        videoId={modalVideo?.id || ''}
        service={modalVideo?.service}
        campaignSlug={campaign.slug}
        aspectRatio={
          modalVideo?.aspectRatio ||
          headerVideo.fields.variations[0]?.fields.aspectRatio
        }
      />
    </div>
  )
}

export default CampaignPage
