import { useMemo } from 'react'

import { ISectionContentBlockFields } from '../../../../types/generated/contentful'
import { contentUtil, richText } from '@/utils'
import IconGroup from './IconGroup'

interface Props {
  content: ISectionContentBlockFields
}

const cyProps = ['backing', 'creation', 'returns']

const HowItWorks = ({ content }: Props) => {
  const items = useMemo(() => {
    if (!content?.items.length) return undefined

    return content.items.map(contentUtil.mapContent)
  }, [content])

  if (!items?.length) return null

  return (
    <div className="rounded border border-core-gray-300">
      <div className="md:flex px-2 py-6" data-cy="TESTING">
        {items.map((item, i) => (
          <IconGroup
            key={item?.id}
            title={item?.title}
            description={richText(item?.text)}
            dataCy={`how-it-works-${cyProps[i]}`}
            image={item?.image}
          />
        ))}
      </div>
    </div>
  )
}

export default HowItWorks
