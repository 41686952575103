import { useCallback, useState } from 'react'
import { VideoBox, CardModal, Type } from '@/components/common'
import CommentSection from '../CommentSection'
import { Play } from '@/components/svg'
import { ISectionLivestreamFields } from 'src/types/generated/contentful'

interface Props
  extends Omit<ISectionLivestreamFields, 'contentfulName' | 'title'> {
  campaignSlug: string
  id?: string
}

const LegacyLivestream: React.FC<Props> = ({
  id,
  livestreams,
  campaignSlug,
}) => {
  const [selectedStream, setSelectedStream] = useState(livestreams?.[0])
  const [isOpen, setIsOpen] = useState(false)
  const idPrefix = id ? `livestream-${id}` : 'livestream'

  const handleClick = useCallback((livestream) => {
    setSelectedStream(livestream)
    setIsOpen(true)
  }, [])

  if (!livestreams?.length) return null

  return (
    <>
      <div>
        {livestreams.map((ls) => (
          <div
            key={ls.fields.id}
            onClick={() => handleClick(ls)}
            className="border-b border-core-gray-300 cursor-pointer gap-2 flex flex-row py-3 items-center last:border-0 last:pb-0"
          >
            <Type as="p" variant="title-md" className="flex-1">
              {ls.fields.title}
            </Type>
            <div className="bg-core-oxide-core rounded-full w-[26px] h-[26px] p-1.5 flex-shrink-0">
              <Play className="text-white" />
            </div>
          </div>
        ))}
      </div>

      {selectedStream && (
        <CardModal isOpen={isOpen} setIsOpen={setIsOpen} width="w-[1024px]">
          <CardModal.Title className="pt-6 pl-6 pr-20">
            <Type as="div" variant="title-lg">
              {selectedStream.fields.title}
            </Type>
          </CardModal.Title>
          <CardModal.Body>
            <VideoBox
              id={`${idPrefix}-video`}
              campaignSlug={campaignSlug}
              video={selectedStream.fields.id}
              service={selectedStream.fields.service}
              aspectRatio={selectedStream.fields.aspectRatio}
              className="mt-5"
            />

            <CommentSection video={selectedStream.fields.id} />
          </CardModal.Body>
        </CardModal>
      )}
    </>
  )
}

export default LegacyLivestream
