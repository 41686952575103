import { CONTENT_BLOCK_LAYOUT } from '../../../../constants/contentful'
import {
  ISectionCastAndCrewFields,
  ISectionContentBlockFields,
  ISectionOverviewFields,
  IVideoBlock,
} from 'src/types/generated/contentful'
import { contentUtil } from '@/utils'
import { Campaign as ICampaign } from '@/services/api/campaign'

import HowItWorks from '@/components/pages/CampaignPages/HowItWorks'
import Overview from '@/components/pages/CampaignPages/DetailComponents/Overview'
import FAQ from '@/components/pages/CampaignPages/FAQ'
import Offering from '@/components/pages/CampaignPages/Offering'
import Perks from '@/components/pages/CampaignPages/Perks'
import CastAndCrew from '@/components/pages/CampaignPages/CastAndCrew'
import MediaGallery from '@/components/pages/CampaignPages/DetailComponents/MediaGallery'
import {
  LegacyLivestreams,
  Livestreams,
} from '@/components/pages/CampaignPages/Livestreams'

interface Props {
  campaign: ICampaign
  content: contentUtil.MappedContent
  idx: number
}

const Dynamic = ({ campaign, content, idx }: Props) => {
  switch (content.contentType) {
    case 'faqSection': {
      const faqs = {
        questions:
          content?.faqs?.length && content.faqs.map(contentUtil.mapContent),
      }
      return <FAQ faq={faqs} />
    }
    case 'mediaGallery':
      return (
        <MediaGallery
          campaign={campaign}
          title=""
          description={content.description}
          items={content.items.map(contentUtil.mapContent)}
        />
      )
    case 'sectionCastAndCrew':
      return (
        <CastAndCrew {...(content as unknown as ISectionCastAndCrewFields)} />
      )
    case 'sectionContentBlock':
      if (content.layout === CONTENT_BLOCK_LAYOUT.CARD) {
        return (
          <HowItWorks
            content={content as unknown as ISectionContentBlockFields}
          />
        )
      } else if (content.layout === CONTENT_BLOCK_LAYOUT.PERKS) {
        return <Perks perks={campaign.perks} />
      }
      break
    case 'sectionOffering': {
      const offerings =
        content?.links?.length && content.links.map(contentUtil.mapContent)
      return <Offering campaignSlug={campaign.slug} offerings={offerings} />
    }
    case 'sectionOverview':
      return (
        <Overview
          campaign={campaign}
          section={content as unknown as ISectionOverviewFields}
          idx={idx}
        />
      )
    case 'sectionPerks':
      return <Perks perks={campaign.perks} />
    case 'sectionLivestream': {
      if (!content.enableLegacyLivestreams) {
        return (
          <Livestreams
            campaignName={campaign.name}
            campaignSlug={campaign.slug}
          />
        )
      }

      const livestreams =
        content.livestreams.length &&
        content.livestreams
          // remove livestreams that don't have fields (i.e. in draft mode)
          .filter((ls: IVideoBlock) => ls.fields?.id && ls.fields?.title)

      return (
        <LegacyLivestreams
          campaignSlug={campaign.slug}
          id={content.id}
          livestreams={livestreams}
        />
      )
    }
    default:
      break
  }
  return null
}

export default Dynamic
