import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IMediaGalleryItemFields } from 'src/types/generated/contentful'
import useCarousel from '@/services/hooks/useCarousel'
import MediaGalleryItem from './MediaGalleryItem'
import useWindowSize from '@/services/hooks/useWindowSize'
import { ButtonBase, CarouselNav, Type } from '@/components/common'
import { Close } from '@/components/svg'
import classNames from 'classnames'
import { Campaign } from '@/services/api/campaign'

export interface Props {
  items: (IMediaGalleryItemFields & { id: string })[]
  initialItem: number
  setIsOpen: (isOpen: boolean) => void
  campaign: Campaign
}

const MediaGalleryCarousel: React.FC<Props> = ({
  items,
  initialItem,
  setIsOpen,
  campaign,
}) => {
  const [showChrome, setShowChrome] = useState(true)
  const [chromeHovered, setChromeHovered] = useState(false)
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  const [
    scrollerRef,
    {
      canScrollBack,
      canScrollForward,
      scrollBack,
      scrollForward,
      scrollToIndex,
      currentPage,
      pages,
      orientation,
    },
  ] = useCarousel<HTMLDivElement>({
    arrowKeyNavigation: true,
  })
  const { innerWidth, innerHeight } = useWindowSize()

  useEffect(() => {
    scrollToIndex(initialItem, { behavior: 'auto' })
  }, [initialItem, scrollToIndex])

  const hideChromeAfterTimeout = useCallback(() => {
    setShowChrome(true)
    if (timeout.current) clearTimeout(timeout.current)
    if (!chromeHovered) {
      timeout.current = setTimeout(() => setShowChrome(false), 5000)
    }
  }, [chromeHovered])

  useEffect(() => {
    const handleMousemove = () => hideChromeAfterTimeout()
    document.addEventListener('mousemove', handleMousemove)
    return () => document.removeEventListener('mousemove', handleMousemove)
  }, [hideChromeAfterTimeout])

  useEffect(hideChromeAfterTimeout, [hideChromeAfterTimeout, currentPage])

  const mouseHandlers = useMemo(
    () => ({
      onMouseOver: () => setChromeHovered(true),
      onMouseOut: () => setChromeHovered(false),
    }),
    []
  )

  return (
    <div
      className="relative bg-core-black"
      style={{ width: innerWidth, height: innerHeight }}
    >
      <div
        ref={scrollerRef}
        className="relative w-full h-full flex overflow-scroll snap-x snap-mandatory hide-scrollbar overscroll-none"
      >
        {items.map((item, idx) => (
          <MediaGalleryItem
            key={item.id}
            {...item}
            containerWidth={innerWidth}
            containerHeight={innerHeight}
            isCurrent={idx === currentPage - 1}
            showDetails={showChrome}
            campaign={campaign}
            mouseHandlers={mouseHandlers}
          />
        ))}
      </div>

      <div
        className={classNames(
          'absolute top-0 left-0 right-0 p-4 md:p-6 pb-12 md:pb-12 flex justify-between items-center text-white z-20 pointer-events-none',
          'bg-gradient-to-b from-[rgba(0,0,0,0.4)] to-[rgba(0,0,0,0)] transition-opacity duration-500',
          { 'lg:opacity-0': !showChrome }
        )}
        {...mouseHandlers}
      >
        <Type as="h4" variant="caption-lg">
          {currentPage} of {pages}
        </Type>

        <ButtonBase
          onClick={() => setIsOpen(false)}
          aria-label="Close Dialog"
          className="pointer-events-auto"
        >
          <Close className="w-8 h-8" />
        </ButtonBase>
      </div>

      <CarouselNav
        onClick={scrollBack}
        active={canScrollBack}
        orientation={orientation}
        direction="back"
        className={classNames('p-4 z-20', {
          'top-auto md:top-0': orientation === 'horizontal',
          'lg:opacity-0': !showChrome,
        })}
      />

      <CarouselNav
        onClick={scrollForward}
        active={canScrollForward}
        orientation={orientation}
        direction="forward"
        className={classNames('p-4 z-20', {
          'top-auto md:top-0': orientation === 'horizontal',
          'lg:opacity-0': !showChrome,
        })}
      />
    </div>
  )
}

export default MediaGalleryCarousel
