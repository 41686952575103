import React from 'react'
import {
  IFaqSection,
  ISectionOverviewFields,
  ISectionContentBlock,
  ITextBlock,
  IVariationContainer,
  IVideoBlock,
  IMediaGallery,
  IContentBlock,
} from 'src/types/generated/contentful'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import { TextBlock, VideoBox, InvestmentChatbot } from '@/components/common'
import Bios from '../Bios'
import Testimonials from '../Testimonials'
import PriorRaisesBreakdown from '../PriorRaisesBreakdown'
import { contentUtil } from '@/utils'
import { CONTENT_BLOCK_LAYOUT } from '../../../../../../constants/contentful'
import ContentfulExperiment from '@/components/contentful/ContentfulExperiment'
import FAQ from '@/components/pages/CampaignPages/FAQ'
import { Campaign } from '@/services/api/campaign'
import MediaGallery from '../MediaGallery'
import Markdown from '@/components/Markdown'

interface Props {
  campaign: Campaign
  section: ISectionOverviewFields
  idx: number
}

type OverviewItem =
  | IFaqSection
  | ISectionContentBlock
  | ITextBlock
  | IVariationContainer
  | IVideoBlock
  | IMediaGallery
  | IContentBlock

export const Overview = ({ campaign, section, idx }: Props) => {
  const siteAssets = useSiteAssets()

  function mapItem(itemRaw: OverviewItem) {
    const item = contentUtil.mapContent(itemRaw)
    if (!item) return null

    const questions =
      item.contentType === 'faqSection'
        ? item.faqs.map(contentUtil.mapContent)
        : []

    return (
      <div key={item.id} className="mb-10 lg:mb-16 last:mb-0">
        {item.contentType === 'sectionContentBlock' &&
          item.layout === CONTENT_BLOCK_LAYOUT.BIO && (
            <Bios items={item.items} />
          )}
        {item.contentType === 'sectionContentBlock' &&
          item.layout === CONTENT_BLOCK_LAYOUT.TESTIMONIAL && (
            <Testimonials items={item.items} />
          )}
        {item.contentType === 'faqSection' && <FAQ faq={{ questions }} />}
        {item.contentType === 'textBlock' && (
          <TextBlock campaignSlug={campaign.slug} document={item.text} />
        )}
        {item.contentType === 'videoBlock' && (
          <VideoBox
            className="-mx-6 lg:mx-0"
            campaignSlug={campaign.slug}
            id={`overview-${item.id}`}
            video={item.id}
            service={item.service}
            aspectRatio={item.aspectRatio}
          />
        )}
        {item.contentType === 'mediaGallery' && (
          <MediaGallery
            title={item.title}
            description={item.description}
            items={item.items.map(contentUtil.mapContent)}
            campaign={campaign}
            className="py-6"
          />
        )}
        {item.contentType === 'variationContainer' && (
          <ContentfulExperiment<OverviewItem>
            variations={item.variations}
            experimentMeta={item.experimentMeta}
          >
            {(variation) => mapItem(variation)}
          </ContentfulExperiment>
        )}
      </div>
    )
  }

  const items = section.content.map(mapItem)

  if (siteAssets?.usePriorRaisesLayout && idx === 0) {
    items.unshift(
      <div key="investment-breakdown" className="mb-6 last:mb-0">
        <Markdown source={siteAssets.campaignSummary} />
        <PriorRaisesBreakdown
          campaign={campaign}
          disclaimer={siteAssets.fundingCampaignDisclaimer}
          className="bg-white border border-core-gray-200 mb-6 rounded-2xl p-5 md:p-8"
        />
        <InvestmentChatbot campaign={campaign} />
      </div>
    )
  }

  return <div className="h-full">{items}</div>
}

export default Overview
