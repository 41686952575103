import useABTest from '@/services/hooks/useABTest'
import { Experiment, ExperimentMeta, Variation } from './types'

function VariationChooser<T extends Variation>({
  variations,
  experimentMeta,
  children,
}: Omit<Experiment<T>, 'experimentMeta'> & { experimentMeta: ExperimentMeta }) {
  const { flagKey, variationKeys } = experimentMeta
  const [decision, clientReady] = useABTest(flagKey)

  // Don't render the component if SDK client is not ready yet.
  if (!clientReady) {
    return null
  }

  // render the first variation if there's a problem with Optimizely
  if (typeof decision !== 'object' || !decision.enabled) {
    return children(variations[0])
  }

  // return variation matching optimizely decision and fall back to first variation
  const variation =
    variations.find(
      ({ sys }) => variationKeys?.[sys.id] === decision?.variationKey
    ) || variations[0]

  return children(variation)
}

export default VariationChooser
