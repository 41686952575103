import { useCallback } from 'react'
import { useRouter } from 'next/router'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { contentUtil } from '@/utils'
import { Type } from '@/components/common'
import { ArrowSquareOut } from '@/components/svg'

export interface OfferingProps extends contentUtil.MappedContent {
  url: string
}

export interface Props {
  campaignSlug: string
  offerings: OfferingProps[]
}

const Offering: React.FC<Props> = ({ campaignSlug, offerings }) => {
  const router = useRouter()

  const handleClick = useCallback(
    (name, url) => {
      SegmentHandler.track('On Tap', {
        project: campaignSlug,
        location: router.asPath,
        section_name: 'body',
        element_name: 'link',
        value: name,
        target_url: url,
        action_type: 'tap',
      })
    },
    [campaignSlug, router.asPath]
  )

  if (!offerings.length) return null

  return (
    <div className="flex flex-col">
      {offerings.map(({ id, name, url }) => (
        <Type
          as="a"
          variant="title-md"
          key={id}
          href={url}
          target="_blank"
          rel="noreferrer"
          className="border-b border-core-gray-300 flex items-center justify-between text-left text-core-oxide-core text-lg font-semibold w-full py-3.5 last:border-b-0 last:pb-0"
          onClick={() => handleClick(name, url)}
        >
          {name}
          <ArrowSquareOut stroke="#16B087" />
        </Type>
      ))}
    </div>
  )
}

export default Offering
