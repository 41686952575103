import { useState, useEffect, useCallback } from 'react'
import qs from 'qs'
import useApi from '@/services/hooks/useApiV2'
import { useRouter } from 'next/router'
import {
  getComments,
  postComment,
  postResponse,
  Params,
  Comment,
  CommentReq,
  CommentList,
} from '@/services/api/publicComments'
import SegmentHandler from '@/services/analytics/SegmentHandler'

export const initialData: CommentList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
}

export interface Options extends Params {
  isPaginated?: boolean
  context?: string // use if need request to cache separately from other useComments calls with same params
}

const useComments = ({
  isPaginated = true,
  ...params
}: Omit<Options, 'campaign'> & { campaign?: string }) => {
  const key = params.campaign
    ? `comments/${qs.stringify(params, {
        addQueryPrefix: true,
      })}`
    : false
  const { data, isLoading, isError } = useApi<CommentList>({
    key,
    request: () => getComments(params as Options),
  })

  const [comments, setComments] = useState(initialData)
  const router = useRouter()

  useEffect(() => {
    if (data) {
      setComments((cur) => {
        if (isPaginated) return data

        return {
          ...data,
          results: [
            ...cur.results,
            ...data.results.filter(
              (d: Comment) => !cur.results.find((c) => c.id === d.id)
            ),
          ],
        }
      })
    }
  }, [data, isPaginated])

  const resolvedPostComment = useCallback(
    async (req: CommentReq) => {
      const comment = await postComment(req)

      SegmentHandler.track('User Message Completed', {
        project: params.campaign,
        location: router.asPath,
        action_type: 'keyboard',
        message_type: 'comment',
        value: req.text,
      })

      setComments((cur) => {
        const newComments = { ...cur, results: [comment, ...cur.results] }
        return newComments
      })

      return comment
    },
    [router.asPath, params.campaign]
  )

  const resolvedPostResponse = useCallback(
    async (req: CommentReq) => {
      const comment = await postResponse(req)

      SegmentHandler.track('User Message Completed', {
        project: params.campaign,
        location: router.asPath,
        action_type: 'keyboard',
        message_type: 'response',
        value: req.text,
      })

      setComments((cur) => {
        const results = [...cur.results]
        const idx = results.findIndex((c) => c.id === comment.parent)
        results[idx].answer = comment
        return { ...cur, results }
      })

      return comment
    },
    [router.asPath, params.campaign]
  )

  return {
    comments,
    isLoading,
    isError,
    postComment: resolvedPostComment,
    postResponse: resolvedPostResponse,
  }
}

export default useComments
