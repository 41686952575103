import React, { useState, useEffect } from 'react'
import { VideoBox } from '@/components/common'
import VideoHero, { Props } from './VideoHero'
import tailwind from '../../../../../tailwind.config'

const VIDEO_BREAK = parseInt(tailwind.theme.screens.xl, 10)

export const MobileFallbackVideoHero: React.FC<Props> = (props) => {
  const [canShow, setCanShow] = useState(false)
  const id = `mobile-video-hero-${props.video}`

  useEffect(() => {
    setCanShow(window?.innerWidth >= VIDEO_BREAK)
  }, [])

  if (canShow) {
    return <VideoHero {...props} />
  }

  return (
    <VideoBox
      id={id}
      video={props.video}
      campaignSlug={props.campaign.slug}
      aspectRatio={props.aspectRatio}
      service={props.service}
      lazyload={true}
      className="w-full"
      useThumbnailBackground={false}
    />
  )
}

export default MobileFallbackVideoHero
