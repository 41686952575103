import React, { useEffect } from 'react'
import { Type } from '@/components/common'
import { Campaign } from '@/services/api/campaign'
import InvestButton from '../../InvestComponents/InvestButton'
import useAuth from '@/services/hooks/useAuth'

export interface Props {
  title: string
  caption?: string
  campaign: Campaign
  className?: string
}

const MediaGalleryItemDetails: React.FC<Props> = ({
  title,
  caption,
  campaign,
  className,
}) => {
  const { modalIsOpen } = useAuth()

  useEffect(() => {
    // This addresses a strange bug in which the inputs of the auth modal are inactive
    // due to some kind of focus trap with the headless UI modal. This happens when the
    // auth modal opens on top of another modal, in this case the media gallery modal.
    if (modalIsOpen) {
      const el = document.activeElement as HTMLElement
      el?.blur?.()
    }
  }, [modalIsOpen])

  return (
    <div className={className}>
      <div className="max-w-xl">
        <Type as="h3" variant={['title-sm', 'md:title-md']}>
          {title}
        </Type>
        {caption && (
          <Type
            as="p"
            variant={['caption-sm', 'md:paragraph-md']}
            className="mt-2"
          >
            {caption}
          </Type>
        )}
        <InvestButton
          size="sm"
          campaign={campaign}
          sectionName="body"
          color="copper"
          className="mt-4"
          tabIndex={-1}
        />
      </div>
    </div>
  )
}

export default MediaGalleryItemDetails
