import { HeightTransitioner, TextBlock, Type } from '@/components/common'
import Image from '@/components/contentful/Image'
import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import React from 'react'
import { ISectionCastAndCrewFields } from 'src/types/generated/contentful'

const CastAndCrew: React.FC<ISectionCastAndCrewFields> = ({
  bios,
  intro,
  teamPortfolio,
}) => {
  return (
    <div>
      {intro && <TextBlock document={intro} className="pb-6" />}
      <Tab.Group>
        <Tab.List className="border-b border-core-gray-300 flex gap-4 mb-8 overflow-x-auto hide-scrollbar">
          {bios.map((bio) => (
            <Tab
              key={bio.sys.id}
              className={classNames(
                'flex-shrink-0 pb-4 border-b-4 border-transparent outline-none ui-selected:border-core-oxide-core'
              )}
            >
              {bio.fields.image && (
                <Image
                  image={bio.fields.image}
                  height={120}
                  width={120}
                  focus="face"
                  fit="fill"
                  className="rounded-full"
                />
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mb-10">
          <HeightTransitioner>
            {bios.map((bio) => (
              <Tab.Panel key={`${bio.sys.id}-panel`}>
                <div className="mb-4">
                  <Type as="p" variant="title-lg" className="font-semibold">
                    {bio.fields.title}
                  </Type>
                  {bio.fields.subtitle && (
                    <Type
                      as="p"
                      variant="title-md"
                      className="mt-1 text-core-gray-600"
                    >
                      {bio.fields.subtitle}
                    </Type>
                  )}
                </div>
                {bio.fields.text && <TextBlock document={bio.fields.text} />}
              </Tab.Panel>
            ))}
          </HeightTransitioner>
        </Tab.Panels>
      </Tab.Group>
      {teamPortfolio && (
        <TextBlock
          document={teamPortfolio.fields.text}
          className="border-t border-core-gray-300 pt-10"
        />
      )}
    </div>
  )
}

export default CastAndCrew
