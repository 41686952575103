import React from 'react'
import classNames from 'classnames'
import { Text } from '@/components/common'
import { formatMoney } from '@/utils'

export interface Props {
  title: string
  color: string
  amount: number
  isLast: boolean
}

const InvestmentBreakdownItem: React.FC<Props> = ({
  title,
  color,
  amount,
  isLast,
}) => {
  return (
    <div
      className={classNames('p-4 flex items-start border-gray-8 border-b', {
        'border-b-0': isLast,
      })}
    >
      <div
        className="w-3 h-3 rounded-full mt-1.5"
        style={{ backgroundColor: color }}
      />
      <div className="flex-1 md:flex pl-4">
        <Text as="div" preset="body.lg" className="font-semibold">
          {title}
        </Text>
        <Text as="div" preset="body.lg" className="font-semibold md:ml-auto">
          {formatMoney(amount, {
            zeroFractionDigits: true,
          })}
        </Text>
      </div>
    </div>
  )
}

export default InvestmentBreakdownItem
